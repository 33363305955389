import { NgClass, NgIf } from '@angular/common';
import { Component, Injectable, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IModalConfig } from '../../core/interface/modal.interface';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [NgIf, NgClass],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
@Injectable()
export class ModalComponent {
  @Input() public modalConfig!: IModalConfig;
  @Input() content!: string;
  @ViewChild('modal') private modalContent!: TemplateRef<ModalComponent>
  closeResult: string = ''
  private modalRef!: NgbModalRef

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void { }

  open(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.modalRef = this.modalService.open(this.modalContent, {
        backdrop: this.modalConfig.backdrop,
        keyboard: this.modalConfig.keyboard,
        size: this.modalConfig.size
      })
      this.modalRef.result.then(resolve, resolve)
    })
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose())
      this.modalRef.close('CLOSED'); // Close the modal with a specific result
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result = this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss())
      this.modalRef.close('DISMISSED'); // Close the modal with a specific result
    }
  }
}
