<!-- https://levelup.gitconnected.com/how-to-create-a-reusable-modal-component-in-angular-9-using-ng-bootstrap-50c0aa5f3a65 -->
<ng-template #modal>
  @if(modalConfig.showXBtn || modalConfig.modalTitle){
  <div class="modal-header" [ngClass]=" modalConfig.customClass">
    <div class="w-100">
      <div class="col-12 text-end">
        <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"
          *ngIf="modalConfig.showXBtn"></button>
      </div>
      <div class="col-12">
        <h4 class="modal-title" id="modal-basic-title" *ngIf="modalConfig.modalTitle">{{ modalConfig.modalTitle }}</h4>
      </div>
    </div>
  </div>
  }
  <div class="modal-body">
    <ng-content></ng-content>
    <div [innerHTML]="content"></div>
  </div>
  <div class="modal-footer border-0">
    @if (!modalConfig.hideDismissButton) {
    <button type="button" class="btn text-white bg-dark py-1" (click)="dismiss()"
      [disabled]="modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()">
      {{ modalConfig.dismissButtonLabel }}
    </button>
    }
    @if (!modalConfig.hideCloseButton) {
    <button type="button" class="text-dark cancel-btn py-1" (click)="close()"
      [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()">
      {{ modalConfig.closeButtonLabel }}
    </button>
    }


  </div>
</ng-template>